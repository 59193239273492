import React from "react";
import { Link, graphql } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import TestimonialsSlider from "../components/Testimonials";

import { BsArrowRight } from "react-icons/bs";
import { BsArrowUpRight } from "react-icons/bs";

import "@brainhubeu/react-carousel/lib/style.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderDots from "../components/SliderDots";

import PAGE_CONTENTS from "../../content/pages/home.json";

class Index extends React.Component {
  state = {
    activeSlide: 0
  };

  constructor(props) {
    super(props);
    this.slider = React.createRef();
  }

  handleSlideChange = (value) => {
    this.slider.slickGoTo(value);
  };

  skipSlide = (e, { next }) => {
    e.preventDefault();

    const { activeSlide, slides } = this.state;

    let newActiveSlide;
    if (next) {
      if (activeSlide === slides.length - 1) {
        newActiveSlide = 0;
      } else {
        newActiveSlide = activeSlide + 1;
      }
    } else {
      if (activeSlide === 0) {
        newActiveSlide = slides.length - 1;
      } else {
        newActiveSlide = activeSlide - 1;
      }
    }

    this.setState({
      ...this.state,
      activeSlide: newActiveSlide
    });
  };

  render() {
    const { data } = this.props;
    let allBrands = data.allMdx.edges;

    const siteTitle = data.site.siteMetadata.title;

    const settings = {
      arrows: false,
      dots: true,
      fade: true,
      infinite: false,
      initialSlide: 0,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      appendDots: (dots) => <SliderDots dots={dots} slider={this.slider} />,
      useTransform: false
    };

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        HeaderExtension={
          <div className="header_extension home">
            <Slider className="cp-slider" {...settings} ref={this.slider}>
              {(PAGE_CONTENTS.heroSlides || []).map((slide, i) => (
                <div key={slide.title}>
                  <div
                    className="cp-slide-box"
                    style={{
                      backgroundImage: `url(${slide.image})`
                    }}
                  >
                    <img
                      src={slide.image_mobile}
                      alt={slide.title}
                      className="is-mobile-only"
                    />

                    <div className={'container cp-content' + (slide.white_text ? ' white' : '')}>
                      <div className="columns">
                        <div className="column is-6">
                          <h1>{slide.title}</h1>

                          <p>{slide.subtitle}</p>

                          {/* INTERNAL LINKS */}
                          {slide.link &&
                            slide.linkText &&
                            !slide.external_link && (
                              <Link to={slide.link} className="button">
                                {slide.linkText} <BsArrowRight />
                              </Link>
                            )}

                          {/* EXTERNAL LINKS */}
                          {slide.link && slide.linkText && slide.external_link && (
                            <a
                              href={`https://${slide.link}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="button"
                            >
                              {slide.linkText} <BsArrowUpRight />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        }
      >
        <SEO
          title="Home"
          titleAsIs={true}
          keywords={[
            "Beauty brands, Personal care, Personal care products, Danielle Beauty, Danielle Mirrors, Therawell, Wanderflower, Studio Dry"
          ]}
        />

        <div className="page-content pages-index">
          <div className="section-ideas">
            <div className="container">
              <h2>The brightest ideas in beauty & wellness</h2>

              <p>
                We innovate premium, affordable personal care brands and share
                them with the world.
              </p>
            </div>
          </div>

          <div className="section-people">
            <div className="container">
              <div className="columns is-vcentered">
                <div className="column is-6">
                  <StaticImage
                    src={"../img/home/uniting_people.jpg"}
                    alt="Uniting people, passion & products"
                    className="img-with-bg"
                  />
                </div>

                <div className="column is-1"></div>

                <div className="column is-4">
                  <h2>Uniting people, passion & products</h2>

                  <p>
                    We carry over 5,000 beauty & wellness products and it all
                    started with a bar of soap in 1969.
                  </p>

                  <Link to="/company" className="button">
                    Get to know us <BsArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="section-brands">
            <div className="container">
              <h2>Our brands</h2>

              <div className="columns is-multiline">
                {allBrands
                  .filter(
                    ({ node }) =>
                      node.frontmatter.categories &&
                      node.frontmatter.isDistributionLine !== true
                  )
                  .slice(0, 8)
                  .map(({ node }) => {
                    const image = getImage(
                      node.frontmatter.thumbnail ||
                        node.frontmatter.featuredImage
                    );

                    return (
                      <div
                        className="column is-3 brand-grid"
                        key={node.frontmatter.title}
                      >
                        <Link to={`/brands${node.fields.slug}`}>
                          <GatsbyImage
                            image={image}
                            alt={node.frontmatter.title}
                          />

                          <p className="cp-category">Beauty</p>

                          <p className="cp-title">
                            {node.frontmatter.title} <BsArrowRight />
                          </p>

                          <p>{node.frontmatter.description}</p>
                        </Link>
                      </div>
                    );
                  })}
              </div>

              <div className="aligncenter">
                <Link to="/brands" className="button">
                  View all brands
                  <BsArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content pages-index">
          <TestimonialsSlider slides={PAGE_CONTENTS.testimonials || []} />
        </div>
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "brands" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            description
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            categories {
              title
              description
              bullets {
                text
              }
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
